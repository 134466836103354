import React, {useState, useEffect} from 'react';
import { AppBar, Box, Button, Chip, Grid, Link, Stack, Toolbar, Typography, styled } from '@mui/material';
import { Container } from '@mui/system';
import demo from './demo2-anon.mp4';
import filterImg from './filters-lp3.png'
import similarImg from './hurry-curry.png'
import similarImg2 from './samurai-stew.png'
import posFilters from './restaurant filters3.png'
import restaurantContacts from './restaurant contacts2.png';
import cloudkitchens from './cloudkitchens.png';
import otter from './otter.png';
import ubereats from './ubereats.png';
import brandPage from './brand_page3.png';
import Cursor from './cursor';

import logoImg from './logo.png'
import { useOnScreen } from './hooks/use-on-screen'; // import the hook

import { keyframes } from '@emotion/react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import AnimatedPath from './animated-path';
import { useResponsive } from './hooks/use-responsive';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import PlaceIcon from '@mui/icons-material/Place';
import GroupsIcon from '@mui/icons-material/Groups';

const StyledButton = styled(Button)`
    &:hover {
      background-color: #1877F2;
      cursor: auto;
    }
`;

function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function App() {
  const smDown = useResponsive("down", "sm");
  const tablet = useResponsive("between", 'sm', 'md');

  const stats = [
    { number: '1M +', label: 'locations + brands + groups', subLabel: undefined },
    { number: '40M +', label: 'pieces of business intel', subLabel: 'at your fingertips' },
    { number: '1M +', label: 'contacts', subLabel: undefined },
    // { number: '12K', label: 'groups' },
    { number: '40 +', label: 'hours saved', subLabel: '*per rep monthly' },
  ];

  const flyInAnimation = keyframes`
    from {
        transform: translateY(-1vh); // Start closer to the final position
        opacity: 0.6;
      }
      to {
        transform: translateY(0); // End at the original position
        opacity: 1;
      }
    `;

  
    const flyBottomAnimation = keyframes`
    from {
        transform: translateY(5vh); // Start closer to the final position
        opacity: 0.6;
      }
      to {
        transform: translateY(0); // End at the original position
        opacity: 1;
      }
    `;

    const opacityAnimation = keyframes`
    from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    `;
  
    const easeOutAnimation = (animation, s="1.5s") => ({
      animation: `${animation} ${s} forwards`
    });
    
    const flyRightAnimation = keyframes`
    from {
        transform: translateX(-3vh); // Start closer to the final position
        opacity: 0.5;
      }
      to {
        transform: translateY(0); // End at the original position
        opacity: 1;
      }
    `;
  
  const titleAnimation = keyframes`
    0% {
        transform: translateY(-1.5vh); // Start closer to the final position
        opacity: 0.0;
      }
      60% {
        transform: translateY(0); // End at the original position
        opacity: 0.8;
      }
      100% {
        transform: translateY(0); // End at the original position
        opacity: 1;
      }
    `;

  const [opacity, setOpacity] = useState(0);

  const handleScroll = () => {
    // Get the current scroll position
    const scrollY = window.scrollY;
    // A value to start the animation (e.g., when the user has scrolled 100px down)
    const startAnimationAt = 100;
    // A value to end the animation (e.g., when the user has scrolled 300px down)
    const endAnimationAt = 300;
    // Calculate the current scroll position as a percentage of the animation range
    const scrollPosition = Math.min((scrollY - startAnimationAt) / (endAnimationAt - startAnimationAt), 1);
    // Update the opacity based on the scroll position
    // If the scrollPosition is 0, opacity is 0.6 ; if scrollPosition is 1, opacity is 1
    setOpacity(0 + scrollPosition * (1));
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [ref, isVisible] = useOnScreen({ threshold: 0.1 });
  const [brandRef, isBrandVisible] = useOnScreen({ threshold: 0.1 });
  const [img1Ref, isImg1Visible] = useOnScreen({ threshold: 0.1 });
  const [img2Ref, isImg2Visible] = useOnScreen({ threshold: 0.1 });
  const companyLogos = [ubereats, cloudkitchens, otter];

  return (
    <Box sx={{ height: '100vh'}}> 
      <ElevationScroll>
        <AppBar position="fixed" elevation={0} component={"nav"} sx={{backgroundColor: 'white', color: 'black'}}>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

            <Toolbar sx={{ justifyContent: 'space-between', width: '90%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>

              <Box
                component="img"
                src={logoImg}
                alt="Description"
                sx={{
                  width: '30px',
                }}
              />
              <Typography variant="h5"  sx={{letterSpacing: '-0.5px', animation: `${flyInAnimation} 0.8s forwards`}}>
                Onesauce
              </Typography>
              </Box>
              <Button variant="contained" color="primary" sx={{ 
                alignSelf: 'center', 
                boxShadow: (theme) => `${theme.customShadows.primary}`,
              }}
              onClick={() => window.open('https://calendly.com/molllified/demo', '_blank')}
              >
                Get started
              </Button>
            </Toolbar>
          </Box>
        </AppBar>
      </ElevationScroll>
      <Box sx={{paddingBottom: 6}}>
        <Container>
          <Container sx={{ paddingTop: 12, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center' 
              }}>
              <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'fontWeightLight', textAlign: 'center', animation: `${titleAnimation} 1s forwards`}}>
                Sales Intelligence for the <Box component="span" sx={{ fontWeight: 'fontWeightMedium' }}>food industry</Box>
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 4, textAlign: 'center', fontWeight: 'fontWeightRegular', width: smDown ? '100%': '60%', animation: `${titleAnimation} 1s forwards`, animationDelay: '0.1s'}}>
              Make smarter prospecting decisions on restaurants. Accelerate your revenue growth with rich industry data & decision maker information.            </Typography>
            </Box>
            <Button variant="contained" size="large" color="primary" sx={{ 
              alignSelf: 'center', justifyContent: 'center', 
              animation: `${titleAnimation} 1s forwards`, 
              animationDelay: '0.12s'
            }}
            onClick={() => window.open('https://calendly.com/molllified/demo', '_blank')}
            >
              Schedule a Demo
            </Button>
          </Container>
          <Box sx={{ position: 'relative', width: '100%', paddingTop: 6}}>
            <Box sx={{animation: `${flyInAnimation} 3s forwards`}}>
              <video autoPlay loop muted playsInline style={{ width: '70%', display: 'block', borderRadius: '10px', margin: 'auto'}}>
                <source src={demo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
            <Box
              component="img"
              src={filterImg}
              alt="Description"
              sx={{
                position: 'absolute',
                borderRadius: '10px',
                top: '25%', // Start from below the video
                left: '20px',
                width: smDown ? '100px' : '200px',
                height: 'auto',
                border: (theme) => `solid 1px ${theme.palette.divider}`,
                boxShadow: (theme) => `${theme.customShadows.secondary}`,
                animation: `${flyRightAnimation} 2s forwards`, // Apply the animation
              }}
            />
          </Box>
        </Container>
        <Stack spacing={6}>
        <Box sx={{ width: '100%', mx: 'auto', paddingTop: tablet? 24: smDown ? 16 : 14, opacity: smDown ? 1 : opacity }}>
          <Grid container spacing={2} justifyContent="space-between">
            {stats.map((stat, index) => (
              <Grid item key={index} xs={3} sm={3}>
                <Typography variant="h5" component="p" sx={{ textAlign: 'center', letterSpacing: '2px' }}>
                  {stat.number}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                  {stat.label}
                </Typography>
                {stat.subLabel && 
                <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                  {stat.subLabel}
                </Typography>
                }
              </Grid>
            ))}
          </Grid>
        </Box>
        
        <Grid container columnGap={5} sx={{ paddingTop: 10, alignItems: 'center' }}>
          {/* Image container */}
          <Grid item xs={12} sm={12} md={7} lg={7} position="relative" sx={{ display: 'block'}}>
            {/* First image */}
           <Box m={4}>
            <Box
              component="img"
              src={brandPage}
              alt="Description"
              sx={{                
                width: '100%',
                maxWidth: '650px',
                position: 'relative',
                borderRadius: '10px',
              }}
            />
            </Box>
          </Grid>

          {/* Explore similar brands card */}
          <Grid item xs={10} sm={7} md={4} lg={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap:1, transform: 'translateY(5vh)',  ...(isBrandVisible && easeOutAnimation(flyBottomAnimation)) }} ref={brandRef}>
          <Box ml={tablet? 4: smDown? 2 : 8} mt={tablet? 4: smDown? 6 : 0} > 

            <Typography variant="h4" sx={{ paddingBottom: 2}}  gutterBottom>
            Bring prospect data to life through brand and location visualization         
            </Typography>
            <Stack spacing={2}>
              <Stack gap={0}>
                <Typography variant="subtitle2" paragraph gutterBottom={false}>
                <TravelExploreOutlinedIcon sx={{ fontSize: '1.2rem', verticalAlign: 'middle', marginRight: 1, color: 'primary.main'}} />
                   Key brand insights
                </Typography>
                <Typography variant="body1" paragraph color="text.primary">
                  See hundreds of brand and location level properties in a single view, with key insights to help you on your discovery.
                </Typography>
              </Stack>
            </Stack>
            </Box>
          </Grid>
        </Grid>

        <Grid container columnGap={3} sx={{ paddingTop: smDown? 5: 10, alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row-reverse'}}>
          {/* Image container */}
          <Grid item xs={12} sm={12} md={7} lg={7} sx={{ 
            display: 'flex', 
            justifyContent: smDown ? 'flex-end' : 'center', // Align the image to the right within the grid item on small screens, center otherwise
            alignItems: 'center', 
            position: 'relative'
          }}>
            {/* First image */}
            <Box sx={{ width: '100%' }}> 
              <Box
                component="img"
                src={posFilters}
                alt="Description"
                sx={{
                  zIndex: 2,
                  // maxHeight: '600px',
                  // Apply visibility-triggered animation if needed
                  ...(isImg1Visible && easeOutAnimation(opacityAnimation, '1s')),
                  position: 'relative', // Ensure the position is relative for the absolute positioning of the lines
                }}
                ref={img1Ref}
              />
            </Box>
          </Grid>

          
          {/* Search value prop */}
          <Grid item xs={10} sm={7} md={4} lg={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap:1 ,  ...(isVisible && {...easeOutAnimation(flyBottomAnimation)}) }} ref={ref}>
            <Box ml={tablet? 4: smDown? 2 : 8} mt={tablet? 4: smDown? 6 : 0} > 
              <Typography variant="h4" sx={{ paddingBottom: 2}} gutterBottom>
                Search with key filters to target your ICP
              </Typography>
              <Stack spacing={2}>
                <Stack gap={0}>
                  <Typography variant="subtitle2" paragraph gutterBottom={false}>
                  <TravelExploreOutlinedIcon sx={{ fontSize: '1.2rem', verticalAlign: 'middle', marginRight: 1, color: 'primary.main'}} />

                  Best in class industry info
                  </Typography>
                  <Typography variant="body1" paragraph color="text.primary">
                  Refreshed weekly, leveraging AI to obtain hard-to-find signals, across small businesses to enterprise brands in the US.
                  </Typography>
                </Stack>
                <Stack gap={0}>
                  <Typography variant="subtitle2" paragraph gutterBottom={false}>
                    <Groups2OutlinedIcon sx={{ fontSize: '1.2rem', verticalAlign: 'middle', marginRight: 1,  color: 'primary.main' }} />
                    Restaurant specific filters
                  </Typography>
                  <Typography variant="body1" paragraph  color="text.primary">
                    Empower your sales team to query for and find the right prospects based on restaurant filters that matter to you.
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>

          
        </Grid>

        <Grid container columnGap={5} sx={{ paddingTop: 10, alignItems: 'center' }}>
          {/* Image container */}
          <Grid item xs={12} sm={12} md={7} lg={7} position="relative" sx={{ display: 'block'}}>
            <Box mx={2} my={3}>
              <Box
                component="img"
                src={restaurantContacts}
                alt="Description"
                sx={{
                  zIndex: 2,
                  position: 'relative', // Ensure the position is relative for the absolute positioning of the lines
                }}
              />
            </Box>
          </Grid>

          {/* Contacts value prop */}
          <Grid item xs={10} sm={7} md={4} lg={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap:1, transform: 'translateY(5vh)',  ...(isBrandVisible && easeOutAnimation(flyBottomAnimation)) }} ref={brandRef}>
          <Box ml={tablet? 4: smDown? 2 : 8} mt={tablet? 4: smDown? 6 : 0} > 

            <Typography variant="h4" gutterBottom>
            Get restaurant ownership & location management contacts            
            </Typography>
            <Typography variant="body1" paragraph color="text.primary">
              <HubOutlinedIcon sx={{ fontSize: '1.2rem', verticalAlign: 'middle', marginRight: 1, color: 'primary.main'}} />
                Unlock key decision makers at the corporate level, location level, and general restaurant contacts.
            </Typography>
            <Typography variant="body1" paragraph color="text.primary">
            <VolunteerActivismOutlinedIcon sx={{ fontSize: '1.2rem', verticalAlign: 'middle', marginRight: 1, color: 'primary.main'}} />
                Unparalleled coverage for mid - market contacts that can't be found anywhere else.
            </Typography>
            </Box>
          </Grid>
        </Grid>


        <Grid container columnGap={5} sx={{ paddingTop: smDown? 5: 10, alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row-reverse'}}>
          {/* Image container */}
          <Grid item xs={10} sm={6} md={5} mx={2} my={3} position="relative" sx={{ display: 'block'}}>
            {/* First image */}
            <Box>
              <Box
                component="img"
                src={similarImg}
                alt="Description"
                sx={{
                  zIndex: 2,
                  maxWidth: '150px',
                  // Apply visibility-triggered animation if needed
                  ...(isImg1Visible && easeOutAnimation(opacityAnimation, '1s')),
                  position: 'relative', // Ensure the position is relative for the absolute positioning of the lines
                }}
                ref={img1Ref}
              />
            </Box>

             

            {/* Second image */}
            
            <Box
              sx={{
                paddingTop: smDown? 14 : 10,
                position: 'relative',
                selfAlign: 'flex-end',
                justifyContent: 'end',
                display: 'flex',
              }}
            >
              <Box sx={{
                position: 'absolute', 
                top: smDown?'30px': '-10px',
                right: '45px',
                padding: 1, bgcolor: 'background.paper', border: (theme) => `solid 1px ${theme.palette.divider}`, borderRadius: '10px',
                boxShadow: (theme) => `${theme.customShadows.secondary}`,
                zIndex: 3,
                opacity: 0,
                ...(isImg2Visible && {...easeOutAnimation(opacityAnimation, '2s'), animationDelay: '0.7s'}),
              }}
              ref={img2Ref}
              >
                <Stack direction="column" gap={1}>
                  <Chip icon={<PlaceIcon sx={{height: '12px', width: '12px'}}/>} label="0.3 mi away" variant="outlined" color="primary" size="small" sx={{fontSize: '11px'}}/>
                  <Chip icon={<GroupsIcon sx={{height: '13px', width: '13px'}}/> } label="Same owner" variant="outlined" color="primary" size="small" sx={{fontSize: '11px'}}/>
                  <Chip icon={<LocalFireDepartmentIcon color="error" sx={{height: '13px', width: '13px'}}/>} label="#2 popular on the block" variant="outlined" color="error" size="small" sx={{fontSize: '11px'}}/>
                </Stack>
                <AutoAwesomeOutlinedIcon sx={{position: 'absolute', top:'-10px', left: '-5px', color: 'primary.main', fontSize: '17px', zIndex: 3}}/>
              </Box>
              <Box
                component="img"
                src={similarImg2}
                alt="Description" 
                sx={{
                  zIndex: 2,
                  width: '125px',
                  opacity: 0,
                  ...(isImg2Visible && {...easeOutAnimation(opacityAnimation, '3s'), animationDelay: '0.1s'}),
                }}
                ref={img2Ref}
              />

            </Box>
            <Box sx={{
              padding: 1, bgcolor: 'background.paper', border: (theme) => `solid 2px ${theme.palette.divider}`, borderRadius: '10px',
              position: 'absolute', zIndex: 2, top: '-20px', left: '40px',
              boxShadow: (theme) => `${theme.customShadows.secondary}`,
            }}>
              <StyledButton variant='contained' size="small" startIcon={<AutoAwesomeIcon sx={{fontSize: '13px !important'}} />} sx={{textWrap: 'nowrap', fontWeight: 'fontWeightRegular'}} disableElevation disableRipple>Discover similar</StyledButton>
              <Box sx={{position: 'absolute', bottom: '-10px', right: '50%',background: 'radial-gradient(circle, rgb(255 255 255) 0%, rgb(255 255 255 / 36%) 70%)', borderRadius: '50%'}}>
                <Cursor sx={{width: '20px', height: '20px', fill: '#000'}}/>
              </Box>
            </Box>

            {/* Dotted Line from first to second image */}
            <AnimatedPath />
          </Grid>
          
          {/* Explore similar brands card */}
          <Grid item xs={10} sm={5} md={4}  sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap:1 ,  ...(isVisible && {...easeOutAnimation(flyBottomAnimation)}) }} ref={ref}>
          <Box ml={tablet? 4: smDown? 2 : 8} mt={tablet? 4: smDown? 6 : 0} > 

          <Typography variant="h4" gutterBottom>
              Discover new opportunities from AI powered recommendations       
            </Typography>
            <Typography variant="body1" paragraph color="text.primary">
              <HubOutlinedIcon sx={{ fontSize: '1.2rem', verticalAlign: 'middle', marginRight: 1, color: 'primary.main'}} />
            5x your sales pipeline by building on top of your existing customer base to find similar brands and recommendations.
            </Typography>
            </Box>
          </Grid>

          
        </Grid>

        </Stack>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', py: 8}}>
        <Stack spacing={2} direction="column">
          <Typography variant='caption'>
            Patented technology built by data analysts and engineers from
          </Typography>
          <Stack direction={"row"} gap={8}>
          {companyLogos.map((src, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 2,
                width: '100%',
              }}
            >
              <Box
                component="img"
                src={src}
                alt="Description"
                sx={{
                  maxWidth: '50px',
                }}
              />
            </Box>
          ))}
        </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          // backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.3)), url(${bgImg})`,
          backgroundSize: 'cover', // Covers the entire Box area
          backgroundPosition: 'center', // Centers the background image
          backgroundColor: '#13213C', // Visible while the image is loading
          paddingX: 4,
          paddingY: 15,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          // height: '100vh', // Adjust the height as needed
        }}
      >
        <Stack direction={"column"} gap={4}>

        <Typography variant="h4" component="h1" gutterBottom>
          Ready to get the secret sauce? 🤫
        </Typography>
        <Button variant="contained" size="large" color="primary" sx={{ alignSelf: 'center', justifyContent: 'center' }} disableElevation onClick={() => window.open('https://calendly.com/molllified/demo', '_blank')}>
          Schedule Demo
        </Button>
        </Stack>

      </Box>
      <Box sx={{ bgcolor: '#13213C', paddingX: smDown ? 5 : 10, paddingY: 8}}>
        <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption" color="background.paper">
            © 2024 Onesauce
          </Typography>
          <Box>
            <Link href="https://drive.google.com/file/d/1AojS-XtwxxhgDj61DPo9fa3Dr1AqF2GS/view?usp=sharing" underline="hover" color="background.paper" variant="caption" sx={{ margin: 1 }}>
              Privacy Policy
            </Link>
            <Link href="https://drive.google.com/file/d/10Md0q4Cux07zYwEiZGN32PegIY56Cuve/view?usp=sharing" underline="hover" color="background.paper" variant="caption" sx={{ margin: 1 }}>
              Terms of Service
            </Link>
          </Box>
         
        </Container>
      </Box>
    </Box>
  );
}

export default App;
